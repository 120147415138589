<template>
  <div>
    <dashboard-page-title @addEvent="$router.push({name: 'addWithdraw'})"
      :showAddBtn="$perHelper('withdrawals:create')"
    >{{$t('withdraw.Withdrawals')}}</dashboard-page-title>
    <main-table :fields="fields" list_url="merchant/wallet/withdraw-requests" :paginationName="$t('withdraw.Withdrawals')"></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
export default {
  components: {},
  mounted () {
    core.index()
  },
  data () {
    return {
      fields: [
        { label: this.$t('main.id'), key: 'id', class: 'text-start' },
        { label: this.$t('main.amount'), key: 'amount', class: 'text-start' },
        { label: this.$t('main.status'), key: 'status', class: 'text-start', type: 'status', objectTranslation: 'withdraw' },
        { label: this.$t('main.created_at'), key: 'created_at', class: 'text-start', type: 'date' },
        {
          label: this.$t('products.control'),
          key: 'actions',
          type: 'actions',
          class: 'text-start',
          actions: [
            {
              showIf: (item) => item.status === 'pending' && this.$perHelper('withdrawals:create'),
              text: 'Delete',
              icon: 'ri-delete-bin-line',
              color: 'danger',
              url: 'merchant/wallet/withdraw-requests',
              titleHeader: this.$t('main.item'),
              question: this.$t('main.deleteConfirmation'),
              textContent: 'name',
              textDeleteButton: this.$t('main.delete'),
              textCancelButton: this.$t('main.cancel'),
              showAlert: true
            }
          ]
        }
      ]
    }
  }
}
</script>
